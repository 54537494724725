import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import * as dashboardActions from './../../reducers/dashboard/actions'
import * as attendeesActions from './../../reducers/attendees/actions'

import Checkin from './Checkin'

import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonIcon,
  IonHeader,
  useIonViewDidEnter,
  IonSearchbar,
  IonFab,
  IonFabButton,
  IonSpinner,
  getConfig,
} from '@ionic/react'
import {
  qrCode as qrCodeIcon,
  search as searchIcon,
  closeOutline,
  refreshCircleOutline,
} from 'ionicons/icons'

import './HomePage.scss'
import Dashboard from './Dashboard'

const HomePage = () => {
  const dispatch = useDispatch()
  const hasActiveOperation = useSelector(
    (state) =>
      state.attendees.results.length > 0 || state.attendees.attendee.id,
  )
  const { imagem, banner, evento_nome } = useSelector((state) => ({
    banner: state.user?.evento?.banner || '',
    imagem:
      (state.user?.evento?.imagem || '').indexOf('https://') === 0
        ? state.user?.evento?.imagem
        : (state.user?.evento?.imagem || '').length > 0
        ? `https://api.ticketandgo.com.br/media/${state.user?.evento?.imagem}`
        : '',
    evento_nome: state.user?.evento?.nome || '',
  }))
  const loading = useSelector((state) => state.dashboard.loading)
  const isLogged = useSelector((state) => state.user.isLogged)

  const [segment, setSegment] = useState('checkin')
  const [showScanner, setShowScanner] = useState(false)

  const getData = () => {
    dispatch(dashboardActions.get())
  }
  const clear = () => dispatch(attendeesActions.clear())

  return isLogged ? (
    <IonPage id="home-page">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Ticket and Go</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Ticket and Go</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div
          className="main-image"
          // style={{
          //   backgroundImage: imagem
          //     ? `url(${imagem})`
          //     : 'url("/assets/img/speakers/party.jpeg")',
          //   backgroundSize: 'revert',
          // }}
        >
          {/* <img
            src={imagem ? imagem : '/assets/img/speakers/party.jpeg'}
            alt=""
            style={{ backgroundSize: 'revert' }}
          /> */}
          <div
            style={{
              position: 'absolute',
              bottom: '0',
              color: 'white',
              padding: '10px',
            }}
          >
            <h3 style={{ textShadow: '1px 1px #333' }}>{evento_nome}</h3>
          </div>
        </div>

        <IonSegment
          onIonChange={(e) => setSegment(e.detail.value)}
          value={segment}
        >
          <IonSegmentButton value="checkin">
            <IonLabel>Checkin</IonLabel>
          </IonSegmentButton>

          <IonSegmentButton value="dashboard">
            <IonLabel>Dashboard</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        {segment === 'checkin' ? (
          <Checkin showScanner={showScanner} />
        ) : (
          <Dashboard />
        )}

        {segment === 'dashboard' ? (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              onClick={() => {
                if (!loading) getData()
              }}
              color="success"
            >
              {loading ? (
                <IonSpinner></IonSpinner>
              ) : (
                <IonIcon icon={refreshCircleOutline} />
              )}
            </IonFabButton>
          </IonFab>
        ) : hasActiveOperation ? (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => clear()} color="danger">
              <IonIcon icon={closeOutline} />
            </IonFabButton>
          </IonFab>
        ) : (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => setShowScanner(!showScanner)}>
              <IonIcon icon={showScanner ? searchIcon : qrCodeIcon} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  ) : (
    <Redirect to="/login" />
  )
}

export default HomePage
