import * as actionTypes from "./actionTypes";

export const search = (codigo) => ({
  type: actionTypes.SEARCH,
  payload: { codigo },
});

export const scan = (codigo) => ({
  type: actionTypes.SCAN,
  payload: { codigo },
});

export const checkin = (attendee) => ({
  type: actionTypes.CHECKIN,
  payload: { attendee },
});

export const results = (results) => ({
  type: actionTypes.RESULTS,
  payload: { results },
});

export const attendee = (attendee) => ({
  type: actionTypes.ATTENDEE,
  payload: { attendee },
});

export const errors = (errors) => ({
  type: actionTypes.ERRORS,
  payload: { errors },
});

export const clear = () => ({
  type: actionTypes.CLEAR,
});

export const loading = (flag) => ({
  type: actionTypes.LOADING,
  payload: { flag },
});
