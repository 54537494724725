import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { help, wineOutline, logIn, logOut, mapOutline, peopleOutline, person, personAdd, scan } from 'ionicons/icons';

import * as userActions from '../reducers/user/actions'

import './Menu.css'

const routes = {
  appPages: [
    { title: 'Checkin', path: '/home', icon: scan },
    //{ title: 'Trocar de evento', path: '/tabs/speakers', icon: wineOutline },
    
  ],
  loggedInPages: [
    { title: 'Account', path: '/account', icon: person },
    { title: 'Support', path: '/support', icon: help },
    { title: 'Logout', path: '/logout', icon: logOut }
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    { title: 'Support', path: '/support', icon: help },
    { title: 'Signup', path: '/signup', icon: personAdd }
  ]
};



const Menu  = ({ darkMode, history, isAuthenticated, setDarkMode, menuEnabled }) => {
  const location = useLocation();

  const dispatch = useDispatch()

  const logoff = () =>  dispatch(userActions.logoff() )

  const isLogged = useSelector(state => state.user.isLogged)

  function renderlistItems(list) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu  type="overlay" disabled={!isLogged} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>Menu principal</IonListHeader>
          {renderlistItems(routes.appPages)}
          {/*<IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Modo escuro</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
  </IonItem>*/}
        </IonList>
        
        <IonList lines="none">
          <IonListHeader>Conta</IonListHeader>
          <IonItem detail={false} onClick={logoff}>
            <IonIcon slot="start" icon={logOut} />
            <IonLabel>Desconectar</IonLabel>
          </IonItem>
          
        </IonList>        
       
      </IonContent>
    </IonMenu>
  );
};

export default Menu
