import React, { useEffect, useState } from 'react'
import { IonSearchbar, IonSpinner, IonButton } from '@ionic/react'

import { useDispatch, useSelector } from 'react-redux'

import * as attendeesActions from '../../reducers/attendees/actions'

import Scanner from '../../components/scanner/Scanner'
import Results from '../../components/attendees/Results'
import AttendeeDetail from '../../components/attendees/AttendeeDetail'

const Checkin = ({ showScanner }) => {
  const dispatch = useDispatch()

  const { loading, results, attendee, evento_nome } = useSelector((state) => ({
    loading: state.attendees.loading,
    results: state.attendees.results.length,
    attendee: state.attendees.attendee,
    evento_nome: state.user?.evento?.nome || '',
  }))

  const [search, setSearch] = useState('')

  const SearchF = (key) => {
    if (key === 'Enter') {
      dispatch(attendeesActions.search(search.toUpperCase()))
    }
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '300px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1>
            <IonSpinner name="crescent" />
          </h1>
        </div>
      ) : attendee?.id ? (
        <AttendeeDetail />
      ) : results > 0 ? (
        <Results />
      ) : showScanner ? (
        <Scanner />
      ) : (
        <>
          <p style={{ textAlign: 'center' }}>Pesquise participantes</p>
          <IonSearchbar
            placeholder="Ingresso ou dados do participante"
            onKeyPress={(e) => SearchF(e.key)}
            onIonChange={(e) => setSearch(e.detail.value)}
            onIonCancel={() => setSearch('')}
          ></IonSearchbar>
          {search.trim() !== '' && (
            <IonButton
              expand="block"
              size="large"
              color="primary"
              onClick={() =>
                dispatch(attendeesActions.search(search.toUpperCase()))
              }
            >
              Pesquisar
            </IonButton>
          )}
        </>
      )}
    </>
  )
}

export default Checkin
