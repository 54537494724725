import React, { useState, useEffect } from 'react';
import { IonIcon, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText } from '@ionic/react';
import './Login.scss';

import { fingerPrintOutline, lockClosedOutline  } from 'ionicons/icons';
import { setIsLoggedIn, setUsername } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import * as userActions from '../reducers/user/actions'
import { Credentials } from '../models/User'




const Login = ({history, match}) => {

  const dispatch = useDispatch()

  const  { user }  = useSelector((state) => ({user : state.user}))

  

  const login = (code, pass) => dispatch(userActions.login(code, pass))
  const logoff = () => dispatch(userActions.logoff())

  const [code, setCode] = useState('');  
  const [pass, setPass] = useState('');  

  

  useEffect(() => {

    if(user.isLogged){
      history.push(`/`, {direction: 'none'});
    }

  },[user.isLogged])

  return (
    <IonPage id="login-page">      
      <IonContent>

        <div className="login-logo">

          <img src="/assets/img/ticketandgo.png" alt="Ticket and Go Logo" />
          
        </div>

        <form noValidate>
          <IonList>            

            <IonItem>
              <IonIcon icon={fingerPrintOutline} slot="start"></IonIcon>
              <IonInput placeHolder="Código do operador" name="username" type="text" value={code} spellCheck={false} autocapitalize="off" onIonChange={e => setCode(e.detail.value)}
                required></IonInput>
            </IonItem>

            <IonItem>
              <IonIcon icon={lockClosedOutline} slot="start"></IonIcon>
              <IonInput type='password' placeHolder="Senha" value={pass} autocapitalize="off" onIonChange={e => setPass(e.detail.value)}></IonInput>
            </IonItem>
            
          </IonList>

          <IonRow>
            <IonCol>
              {
                !user.loading ? 
                <IonButton type="button" onClick={() => login(code, pass)} expand="block" size="large" >
                   Login 
                </IonButton>
                :
                <IonButton type="button" disabled expand="block" size="large" >
                  <ion-spinner name="crescent"></ion-spinner>
                </IonButton>
              }
              
            </IonCol>            
          </IonRow>
          
        </form>

      </IonContent>

    </IonPage>
  );
};

export default Login