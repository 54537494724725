import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';


const HomeOrLogin = ( { match } ) => {

  const isLogged = useSelector(state => state.user.isLogged )  

  return  isLogged ? <Redirect to={`/home`} /> : <Redirect to={`/login`} />
};

export default HomeOrLogin;