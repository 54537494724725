import * as actionTypes from './actionTypes'

export const add = (message) => ({
    type: actionTypes.ADD,
    payload : { 
        message, 
        type: 'default'
     }
})

export const info = (message) => ({
    type: actionTypes.INFO,
    payload : {
        message, 
        type: 'info'
    } 
})

export const success = (message) => ({
    type: actionTypes.SUCCESS,
    payload : {
        message, 
        type: 'success'
    } 
}) 

export const warning = (message) => ({
    type: actionTypes.WARNING,
    payload : {
        message, 
        type: 'warning'
    } 
}) 

export const error = (message) => ({
    type: actionTypes.ERROR,    
    payload : {
        message, 
        type: 'error'
    } 
})

export const clear = () => ({
    type: actionTypes.CLEAR,    
})