import React, { useEffect, useState } from 'react'
import { IonSearchbar, IonSpinner, IonGrid, IonRow, IonCol } from '@ionic/react'

import { useDispatch, useSelector } from 'react-redux'

import AttendeeTile from './AttendeeTile'

import * as attendeesActions from '../../reducers/attendees/actions'

const Results = () => {
  const dispatch = useDispatch()

  const { loading, results } = useSelector((state) => state.attendees)

  return (
    <IonGrid fixed>
      <IonRow>
        {results.map((attendee, idx) => (
          <IonCol size="12" size-md="6" key={idx}>
            <AttendeeTile key={idx} info={attendee} />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  )
}

export default Results
