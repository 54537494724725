import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { IonReactHashRouter } from '@ionic/react-router'
import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify'

import * as notificationActions from './reducers/notification/actions'

import Menu from './components/Menu'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
import 'react-toastify/dist/ReactToastify.min.css'

/* Theme variables */
import './theme/variables.css'
import HomePage from './pages/home/HomePage'

import Login from './pages/Login'
import HomeOrLogin from './components/HomeOrLogin'

const App = () => {
  const dispatch = useDispatch()

  const close = () => dispatch(notificationActions.clear())

  const notification = useSelector((state) => state.notification)
  const isLogged = useSelector((state) => state.user.isLogged)

  useEffect(() => {
    if (notification.type !== 'none') {
      switch (notification.type) {
        case 'success':
          toast.success(notification.message, {
            onClose: () => close(),
          })
          break
        case 'info':
          toast.info(notification.message, {
            onClose: () => close(),
          })
          break
        case 'warning':
          toast.warning(notification.message, {
            onClose: () => close(),
          })
          break
        case 'error':
          toast.error(notification.message, {
            onClose: () => close(),
          })
          break
        default:
          toast(notification.message, {
            onClose: () => close(),
          })
      }
    }
  }, [notification.message])

  return (
    <IonApp className={`${false ? 'dark-theme' : ''}`}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <IonReactHashRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" component={HomeOrLogin} exact />
            <Route path="/login" component={Login} exact />
            <Route path="/home" render={(props) => <HomePage {...props} />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactHashRouter>
    </IonApp>
  )
}

export default App
