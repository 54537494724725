import React, { useState, useRef, useEffect } from 'react';
//import  QrReader from 'react-qr-scanner'
import  { QrReader } from 'react-qr-reader'
import { useDispatch, useSelector } from 'react-redux'
import * as attendeesActions from '../../reducers/attendees/actions'


import { 
  IonToolbar, 
  IonContent, 
  IonPage, 
  IonButtons, 
  IonTitle, 
  IonMenuButton,   
  IonButton, 
  IonIcon, 
  IonHeader,
  useIonViewDidEnter,
  useIonViewWillLeave, 
  IonSpinner    
      
} from '@ionic/react';
import { scan, closeCircle  } from 'ionicons/icons';


import './Scanner.scss'






const Scanner = () => {

  const dispatch =  useDispatch()
  const { draft, loading } = useSelector(state => state.contacts )

  const evento_nome = useSelector(state => state.user?.evento?.nome || "")


  const sendScan = (codigo) => dispatch(attendeesActions.scan(codigo))

  const [data, setData] = useState('');  

  useEffect(() => {
    if(data){
      sendScan(data)
    }
  }, [data])
  

  return (
    

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            
            <div style={{ display: 'flex', width: '100%', padding: '30px', alignItems:'center', justifyContent:'center', flexDirection:'column',}}>
            {
              
              loading ? 
              <h1 style={{textAlign:'center'}}><IonSpinner /></h1>
              :
              <>
              <h4 style={{textAlign:'center'}}>Posicione o QRcode</h4>
              <QrReader
              className="qr-reader"
                scanDelay={2500}                    
                    
                    onResult={(result, error) => {

                                            
                      if(!!result){
                        
                        const text =  result?.text || ''
                        
                        if(text.trim() !== ''){
                          setData(text.trim())
                        }
                      }
                    }}
                    constraints={{ facingMode: 'environment' }}
                    videoStyle={{ width: '100%', aspectRatio: '1', objectFit: 'cover', borderRadius: '20px', border: '3px dashed #09f'}}
                />
                </>
            }
            
            </div>
            
            
        </div>
      
  );
};

export default Scanner;