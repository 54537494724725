import { takeEvery, put, takeLatest, call } from 'redux-saga/effects'
import Requester from '../util/requester'

import * as contactsActions from '../reducers/contacts/actions'
import * as notificationActions from '../reducers/notification/actions'
import { v4 as uuidv4 } from 'uuid'


const _scan = (values) => Requester('/v1/attendees/getcontact', {
    method: 'POST',
    body: values
})

const _addSaga = (values) => Requester('/v1/attendees/addcontact', {
    method: 'POST',
    body: values
})

const _getVcf = (values) => Requester('/v1/attendees/vcard', {
    method: 'POST',
    body: values
})

export function* scan(action) {

    yield put(contactsActions.setLoading(true))

    try {
        const { qrcode } = action.payload
        const result = yield call(_scan, { qrcode })

        
        if(result.status){
            
            const { user }  = result            

            yield put(contactsActions.addDraft(user))
        }
        else{
            yield put(notificationActions.error('Participante não localizado'))
        }
    } catch(error){
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet'))
    }
    finally{
        yield put(contactsActions.setLoading(false))
    }
}

export function* addSaga(action) {

    console.log(action);

    try {
        
        const result = yield call(_addSaga, action.payload)

        
        if(result.status){
            
            yield put(notificationActions.success('Contato incluido com sucesso'))
        }
        else{
            yield put(notificationActions.success('Falha ao tentar incluir o contato'))
        }
    } catch(error){
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet'))        
    }
    finally{
        console.log('Fim')
    }
}

export function* getVcf(action) {

    //yield put(contactsActions.setLoading(true))

    try {
        const { id } = action.payload
        const blob = yield call(_getVcf, { id })

        const name  = uuidv4()+'.vcf'

        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        
    } catch(error){        
        yield put(notificationActions.error('Falha de comunicação. Verifique sua conexão com a internet'))        
    }
    finally{
        //yield put(contactsActions.setLoading(false))
    }
}