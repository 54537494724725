import { takeEvery, put, takeLatest, call, delay } from "redux-saga/effects";
import Requester from "../util/requester";

import * as attendeesActions from "../reducers/attendees/actions";
import * as notificationActions from "../reducers/notification/actions";

const _scan = (values) =>
  Requester("/operador/searchattendee", {
    method: "POST",
    body: values,
  });

const _search = (values) =>
  Requester("/operador/searchattendee", {
    method: "POST",
    body: values,
  });

const _checkin = (values) =>
  Requester("/operador/checkin", {
    method: "POST",
    body: values,
  });

export function* scan(action) {
  yield put(attendeesActions.loading(true));

  try {
    const { codigo } = action.payload;
    const result = yield call(_scan, { codigo });

    if (!result.disconnected) {
      if (result.status === "success") {
        const { data } = result;

        yield put(attendeesActions.results(data));
      } else {
        if (result.message == "-Offline-") {
          yield put(
            notificationActions.success(
              "Leitura realizada com sucesso! [Modo Offline]"
            )
          );
        } else {
          yield put(notificationActions.error(result.message));
        }
      }
    }
  } catch (error) {
    yield put(
      notificationActions.error(
        "Falha de comunicação. Verifique sua conexão com a internet"
      )
    );
  } finally {
    yield put(attendeesActions.loading(false));
  }
}

export function* search(action) {
  yield put(attendeesActions.loading(true));

  try {
    const { codigo } = action.payload;
    const result = yield call(_search, { codigo });

    if (!result.disconnected) {
      if (result.status === "success") {
        const { data } = result;

        yield put(attendeesActions.results(data));
      } else {
        if (result.message == "-Offline-") {
          yield put(
            notificationActions.success(
              "Leitura realizada com sucesso! [Modo Offline]"
            )
          );
        } else {
          yield put(notificationActions.error(result.message));
        }
      }
    }
  } catch (error) {
    yield put(
      notificationActions.error(
        "Falha de comunicação. Verifique sua conexão com a internet"
      )
    );
  } finally {
    yield put(attendeesActions.loading(false));
  }
}

export function* checkin(action) {
  yield put(attendeesActions.loading(true));

  try {
    const result = yield call(_checkin, action.payload);

    const { data } = result;

    if (!result.disconnected) {
      if (result.status === "success") {
        yield put(attendeesActions.clear());
        yield put(notificationActions.success(data.message));
      } else {
        yield put(notificationActions.error(result.message));
      }
    }
  } catch (error) {
    yield put(
      notificationActions.error(
        "Falha de comunicação. Verifique sua conexão com a internet"
      )
    );
  } finally {
    yield put(attendeesActions.loading(false));
  }
}
